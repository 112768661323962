.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.host-video {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.video-box video {
  object-fit: contain !important;
}
.host-video .video-box {
  width: 100%;
  height: 100%;
}

.audience-panel {
  background-color: #faf9f6;
  padding: 10px;
}

.participant-video {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.participant-icons {
  display: flex;
  gap: 5px;
  margin-top: 5px;
}

.footer {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 10px;
  background-color: #faf9f6;
}

div#local-player video {
  object-fit: contain !important;
}
ul.ant-card-actions li {
  margin: 5px 0 !important;
}

.remote-user-card .ant-card-head-title {
  display: flex !important;
  justify-content: space-between;
}

.remote-user-card .ant-card-head {
  position: absolute;
  z-index: 99;
  width: 100%;
}

.remote-user-pin-wrapper {
  position: absolute;
  display: none;
}
.video-box:hover .remote-user-pin-wrapper {
  display: block;
}

.footer-menu-more ul {
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.footer-menu-more ul li {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.list-item-meta-description .ant-list-item-meta-description {
  word-wrap: break-word;
}