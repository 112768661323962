/* Default avatar styling */
.avatar-speaking {
    border: 3px solid transparent;
    border-radius: 50%;
    transition: border 0.3s ease;
  }
  
  /* Blinking border effect for speaking */
  .speaking .avatar-speaking {
    border: 3px solid #4caf50;
    animation: pulse 1.2s infinite ease-in-out;
  }
  
  /* Blinking or wave effect */
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.7);
    }
    50% {
      box-shadow: 0 0 0 15px rgba(76, 175, 80, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
    }
  }
  